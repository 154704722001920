<template lang="pug">
	.widget-container
		el-row(:gutter="24")
			el-col(:xs="24")
				el-card.box-card
					.clearfix.d-flex.justify-content-between(slot="header")
						h2.no-line-height.text-left.w-100 User {{type}}
						.extra
					el-form(ref="form" :model="form" :rules="rules" layout="vertical")
						el-row.text-left()
							h5 Basic details
						el-row(:gutter="24")
							el-col(:xs="24" :sm="8" :lg="6")
								el-form-item(label="First Name" has-feedback="" ref="itemFirstName" prop="first_name")
									el-input(v-model="form.first_name")
							el-col(:xs="24" :sm="8" :lg="6")
								el-form-item(label="Last Name" has-feedback="" ref="itemLastName" prop="last_name")
									el-input(v-model="form.last_name")
							el-col(:xs="24" :sm="8" :lg="8")
								el-form-item(label="E-Mail" has-feedback="" ref="itemEmail" prop="email")
									el-input(v-model="form.email")
						el-row.text-left()
							h5 Credentials
						el-row(:gutter="24")
							el-col(:xs="24" :sm="8" :lg="6")
								el-form-item(label="Username" has-feedback="" ref="itemUsername" prop="username")
									el-input(v-model="form.username")
							el-col(:xs="24" :sm="8" :lg="12")
								el-form-item(has-feedback="" ref="itemPassword" prop="password" )
									template(slot="label")
										label Password&nbsp;&nbsp;
										small.text-danger(v-if="!computedType")
											i (leave empty to leave current password)
									el-input(v-model="form.password" placeholder="Password " type="password" :show-password="true")
							el-col(:xs="24" :sm="8" :lg="6")
								el-form-item(label="Role" has-feedback="" ref="itemRole" prop="role")
									el-select.w-100(v-model="form.role")
										el-option(value="" label="- Select Role -", disabled="" selected="" )
										el-option(v-for="o of options.roles" :value="o.v" :label="o.t")

						el-divider

					el-row(:gutter="12")
						el-col(:span="24")
							el-button(type="primary" size="midi" @click="submit") Save
							el-button(type="danger" size="midi" @click="$router.push('/group/list')") Cancel
</template>

<style lang="scss">

</style>

<script lang="js">
import {GetOptions} from "@/api/option";
import {GetEntity, UpdateEntity, CreateEntity} from "@/api/user";
import {
	validateAlphaNumeric,
	validateEmail, validateEmailExists,
	validateEntityName,
	validatePass,
	validatePassMandatory, validateUsername, validateUsernameExists
} from "@/utils/validate";

let $form = {};

$form = {
	username: '',
	first_name: '',
	last_name: '',
	password: '',
	email: '',
	role: 'WORKER',

};

export default  {
	async beforeRouteEnter(to, from, next) {
		console.log(to.path === '/user/create')
		if(to.path !== '/user/create' && to.path !== '/user/edit/' + to.params.id) return next('/user/list');
		if(to.path === '/user/create') {
			try {
				const options = await GetOptions({filters:'role'});

				next(vm => {
					vm.form = {...$form};
					vm.type = 'Create';
					vm.options = {};
					vm.options.roles = options.role
				})

			} catch(e) {
				console.error(e);
				return next('/user/list');
			}
		} else if(to.path === '/user/edit/' + to.params.id) {
			try {
				let entity = await GetEntity(to.params.id);
				const options = await GetOptions({filters:'role'})
				next(vm => {

					vm.type = 'Edit';
					vm.options = {};
					vm.form = {...$form};
					vm.form.first_name = entity.first_name;
					vm.form.last_name = entity.last_name;
					vm.form.username = entity.username;
					vm.form.email = entity.email;
					vm.form.role = entity.role;
					vm.originalData = {...vm.form};
					vm.options.roles = options.role
				})
			} catch(e) {
				console.error(e);
				return next('/user/list');
			}
		}
	},
	computed: {
		computedType() {
			return this.$route.path === '/user/create';
		}
	},
	data() {
		const type = this.$route.path === '/user/create';
		return {
			form: $form,
			originalData: $form,
			type: '',
			rules: {
				username: { required: true, validator: !type ? validateUsername : validateUsernameExists, trigger: 'blur' },
				first_name: { required: true, validator: validateEntityName, trigger: 'blur' },
				last_name: { required: true, validator: validateEntityName, trigger: 'blur' },
				email: { required: true, validator: !type ? validateEmail : validateEmailExists, trigger: 'blur' },
				password: { required: type, validator: type ? validatePassMandatory : validatePass, trigger: 'blur' },
			},
			options: {
				roles: []
			}
		}
	},
	mounted() {

	},
	methods: {
		submit() {
			this.$refs.form.validate(async valid => {
				if(!valid) return this.$message.error('Validation failed');
				if (this.type === 'Create') {
					CreateEntity(this.form)
							.then(res => {
								this.$notify.success({title: 'API Response', message: 'User successfully created'});
								this.$router.push('/user/list');
							})
							.catch(err => console.error(err))
				} else if (this.type === 'Edit') {
					UpdateEntity(this.$route.params.id, this.form)
							.then(res => {
								this.$notify.success({title: 'API Response', message: 'User successfully updated'});
								this.$router.push('/user/list');
							})
							.catch(err => console.error(err))
				}
			});
		}
	}
}
</script>
